import React, { useState } from 'react';
import Quiz from "./Components/Quiz/Quiz";
import Quiz2 from "./Components/Quiz/Quiz2";
import Login from './Components/Login';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedQuiz, setSelectedQuiz] = useState(null);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setSelectedQuiz(null); // Reset selectedQuiz when logging out
  };

  const handleSelectQuiz = (quizType) => {
    setSelectedQuiz(quizType);
  };

  return (
    <div>
      {!isLoggedIn ? (
        <Login onLogin={handleLogin} />
      ) : selectedQuiz ? (
        selectedQuiz === 'Quiz' ? (
          <Quiz onLogout={handleLogout} />
        ) : (
          <Quiz2 onLogout={handleLogout} />
        )
      ) : (
        <div className='p-5 m-3'>
          <h1 className='text-light m-3'>Select a quiz:</h1>
          <button className='btn btn-lg btn-warning m-3' onClick={() => handleSelectQuiz('Quiz')}>Quiz 1</button>
          <button className='btn btn-lg btn-warning m-3' onClick={() => handleSelectQuiz('Quiz2')}>Quiz 2</button>
        </div>
      )}
    </div>
  );
};

export default App;
