/* find the duplicates in the following questions */
export const data = [
  {
    question: "What is the default value for the Cooldown Period in an Auto Scaling Group?",
    option1: "100 seconds",
    option2: "200 seconds",
    option3: "300 seconds",
    option4: "400 seconds",
    ans: 3
  },
  {
    question: "Which service allows instances in a private subnet to access the internet while remaining private?",
    option1: "Internet Gateway",
    option2: "NAT Gateway",
    option3: "VPC Endpoint",
    option4: "Direct Connect",
    ans: 2
  },
  {
    question: "Which AWS service provides hardware security modules in AWS Cloud?",
    option1: "AWS CloudHSM",
    option2: "AWS KMS",
    option3: "AWS IAM",
    option4: "AWS Shield",
    ans: 1
  },
  {
    question: "What is the maximum retention period for data in Kinesis Data Streams?",
    option1: "1 day",
    option2: "7 days",
    option3: "30 days",
    option4: "365 days",
    ans: 4
  },
  {
    question: "Which S3 storage class is suitable for data that is accessed less frequently but requires rapid access when needed?",
    option1: "S3 Standard",
    option2: "S3 Standard-IA",
    option3: "S3 One Zone-IA",
    option4: "S3 Glacier",
    ans: 2
  },
  {
    question: "What type of routing policy allows you to route traffic based on the lowest latency for your end user?",
    option1: "Simple",
    option2: "Weighted",
    option3: "Failover",
    option4: "Latency-based",
    ans: 4
  },
  {
    question: "What is a feature of Amazon Aurora that provides continuous backups?",
    option1: "Scheduled Snapshots",
    option2: "Read Replicas",
    option3: "Backtrack",
    option4: "Multi-AZ",
    ans: 3
  },
  {
    question: "Which of the following is NOT a supported destination for Kinesis Data Firehose?",
    option1: "S3",
    option2: "Redshift",
    option3: "DynamoDB",
    option4: "OpenSearch",
    ans: 3
  },
  {
    question: "Which type of AWS VPN connection offers encrypted data transmission over the public internet?",
    option1: "Client VPN",
    option2: "Direct Connect",
    option3: "Site-to-Site VPN",
    option4: "Transit Gateway",
    ans: 3
  },
  {
    question: "In SQS, what is the default visibility timeout for a message?",
    option1: "10 seconds",
    option2: "30 seconds",
    option3: "1 minute",
    option4: "5 minutes",
    ans: 2
  },

  {
    question: "How many read replicas can you create in Amazon RDS?",
    option1: "Up to 10 read replicas",
    option2: "Up to 15 read replicas",
    option3: "Up to 20 read replicas",
    option4: "Up to 25 read replicas",
    ans: 2
  },
  {
    question: "What type of encryption does AWS CloudHSM support?",
    option1: "Only symmetric key encryption",
    option2: "Only asymmetric key encryption",
    option3: "Both symmetric and asymmetric key encryption",
    option4: "Only hash-based encryption",
    ans: 3
  },
  {
    question: "Which service can automatically scale the number of shards in a Kinesis Data Stream?",
    option1: "Kinesis Auto-Scaler",
    option2: "AWS Auto Scaling",
    option3: "Kinesis Data Analytics",
    option4: "No service; manual scaling is required",
    ans: 4
  },
  {
    question: "What is the main advantage of using AWS Kinesis Data Firehose?",
    option1: "Low latency writes",
    option2: "Serverless and fully managed service",
    option3: "Supports replay capability",
    option4: "Provides enhanced fan-out",
    ans: 2
  },
  {
    question: "Which of the following is a deployment option in Elastic Beanstalk that involves no downtime?",
    option1: "All at once",
    option2: "Rolling",
    option3: "Immutable",
    option4: "Traffic Splitting",
    ans: 3
  },
  {
    question: "What is the primary use of Amazon SQS Delay Queue?",
    option1: "To ensure FIFO ordering",
    option2: "To delay the delivery of new messages to the queue",
    option3: "To store undeliverable messages",
    option4: "To automatically scale the queue",
    ans: 2
  },
  {
    question: "In AWS CloudFormation, which capability is required to create resources that are IAM roles?",
    option1: "CAPABILITY_NAMED_IAM",
    option2: "CAPABILITY_AUTO_EXPAND",
    option3: "CAPABILITY_RESOURCE_IAM",
    option4: "CAPABILITY_IAM",
    ans: 1
  },
  {
    question: "Which of the following is a key feature of Amazon Aurora?",
    option1: "Supports only MySQL",
    option2: "Only supports manual failover",
    option3: "Grows in increments of 10GB up to 128TB",
    option4: "Does not support cross-region replication",
    ans: 3
  },
  {
    question: "What does the acronym TLS stand for?",
    option1: "Transport Layer Security",
    option2: "Transport Layer Standard",
    option3: "Transfer Layer Security",
    option4: "Transfer Layer Standard",
    ans: 1
  },


  {
    question: "Which of the following is true about SQS standard queue?",
    option1: "Allows duplicate messages",
    option2: "Does not support message visibility timeout",
    option3: "Supports exactly-once processing",
    option4: "Does not allow out-of-order message delivery",
    ans: 1
  },
  {
    question: "What is the maximum retention period for SQS messages?",
    option1: "1 day",
    option2: "7 days",
    option3: "14 days",
    option4: "30 days",
    ans: 3
  },
  {
    question: "In Kinesis Data Streams, what is the maximum data retention period?",
    option1: "1 day",
    option2: "7 days",
    option3: "30 days",
    option4: "365 days",
    ans: 4
  },
  {
    question: "Which Kinesis service provides real-time analytics on data streams?",
    option1: "Kinesis Data Streams",
    option2: "Kinesis Data Firehose",
    option3: "Kinesis Data Analytics",
    option4: "Kinesis Client Library",
    ans: 3
  },
  {
    question: "Which of the following is not a Kinesis Data Firehose destination?",
    option1: "S3",
    option2: "Redshift",
    option3: "Elastic Beanstalk",
    option4: "OpenSearch",
    ans: 3
  },
  {
    question: "What is the maximum message size for SQS?",
    option1: "128 KB",
    option2: "256 KB",
    option3: "512 KB",
    option4: "1024 KB",
    ans: 2
  },
  {
    question: "What is a key feature of AWS CloudHSM?",
    option1: "Fully managed service with auto-scaling",
    option2: "Hardware security module for secure key storage",
    option3: "Serverless compute service",
    option4: "Managed data warehouse service",
    ans: 2
  },
  {
    question: "Which auto-scaling policy adjusts based on CloudWatch metrics?",
    option1: "Predictive scaling",
    option2: "Scheduled scaling",
    option3: "Target tracking",
    option4: "Manual scaling",
    ans: 3
  },
  {
    question: "Which ECS service auto-scaling type is based on CloudWatch alarms?",
    option1: "Target tracking",
    option2: "Step scaling",
    option3: "Scheduled scaling",
    option4: "Predictive scaling",
    ans: 2
  },
  {
    question: "Which ECS deployment option involves zero downtime and high cost?",
    option1: "All at once",
    option2: "Rolling",
    option3: "Immutable",
    option4: "Blue Green",
    ans: 3
  },
  {
    question: "Which AWS service integrates with third-party destinations such as Splunk and DataDog?",
    option1: "Kinesis Data Streams",
    option2: "Kinesis Data Firehose",
    option3: "Kinesis Data Analytics",
    option4: "Kinesis Client Library",
    ans: 2
  },
  {
    question: "What is the maximum number of subscriptions per SNS topic?",
    option1: "1,000,000",
    option2: "10,000,000",
    option3: "12,500,000",
    option4: "15,000,000",
    ans: 3
  },
  {
    question: "What type of integration allows API Gateway to pass the incoming request directly to the backend HTTP endpoint?",
    option1: "AWS integration",
    option2: "AWS_PROXY",
    option3: "HTTP integration",
    option4: "HTTP_PROXY",
    ans: 4
  },
  {
    question: "Which service provides fully managed batch write to S3, Redshift, and OpenSearch?",
    option1: "Kinesis Data Streams",
    option2: "Kinesis Data Firehose",
    option3: "Kinesis Data Analytics",
    option4: "Kinesis Client Library",
    ans: 2
  },
  {
    question: "Which ECS task placement strategy is cost-saving and uses the least resources?",
    option1: "Random",
    option2: "Binpack",
    option3: "Spread",
    option4: "MemberOf",
    ans: 2
  },

  {
    question: "What type of storage does Amazon Aurora use for data replication?",
    option1: "S3",
    option2: "EBS",
    option3: "Shared storage volumes",
    option4: "DynamoDB",
    ans: 3
  },
  {
    question: "How many read replicas can RDS support?",
    option1: "5",
    option2: "10",
    option3: "15",
    option4: "20",
    ans: 3
  },
  {
    question: "What is the purpose of RDS Proxy?",
    option1: "To provide a managed NoSQL database service",
    option2: "To pool and share database connections",
    option3: "To provide an in-memory caching service",
    option4: "To enable auto-scaling of RDS instances",
    ans: 2
  },
  {
    question: "What is the maximum buffer interval for Kinesis Data Firehose?",
    option1: "300 seconds",
    option2: "600 seconds",
    option3: "900 seconds",
    option4: "1200 seconds",
    ans: 3
  },
  {
    question: "Which integration type in API Gateway passes the incoming request directly to the Lambda function?",
    option1: "AWS",
    option2: "AWS_PROXY",
    option3: "HTTP",
    option4: "HTTP_PROXY",
    ans: 2
  },
  {
    question: "What is the retention period for messages in an SQS standard queue?",
    option1: "1-7 days",
    option2: "4-14 days",
    option3: "1-30 days",
    option4: "1-14 days",
    ans: 2
  },
  {
    question: "Which type of scaling does ECS Service auto-scaling use?",
    option1: "Target tracking",
    option2: "Step scaling",
    option3: "Scheduled scaling",
    option4: "All of the above",
    ans: 4
  },
  {
    question: "What does the IAM Role within ECS define?",
    option1: "Permissions for container instances",
    option2: "Permissions for S3 buckets",
    option3: "Permissions within the task definition",
    option4: "Permissions for CloudWatch",
    ans: 3
  },
  {
    question: "What is the default value for the Cooldown Period in an Auto Scaling Group?",
    option1: "100 seconds",
    option2: "200 seconds",
    option3: "300 seconds",
    option4: "400 seconds",
    ans: 3
  },
  {
    question: "What is AWS CloudHSM used for?",
    option1: "Storing static website content",
    option2: "Managing Docker containers",
    option3: "Processing cryptographic operations",
    option4: "Hosting serverless functions",
    ans: 3
  },

  {
    question: "What is the purpose of AWS CloudHSM?",
    option1: "Generate cryptographic keys",
    option2: "Store cryptographic keys securely",
    option3: "Perform cryptographic operations",
    option4: "All of the above",
    ans: 4
  },
  {
    question: "Which of the following is true about Amazon Aurora?",
    option1: "Compatible with MySQL and PostgreSQL",
    option2: "Supports only MySQL",
    option3: "Cannot be used with PostgreSQL",
    option4: "Not compatible with any other databases",
    ans: 1
  },
  {
    question: "What is the default Cooldown Period for an Auto Scaling Group?",
    option1: "600 seconds",
    option2: "300 seconds",
    option3: "120 seconds",
    option4: "60 seconds",
    ans: 2
  },
  {
    question: "Which deployment option in Beanstalk involves creating new instances in a new ASG and swapping all when healthy?",
    option1: "Rolling",
    option2: "Rolling with additional batches",
    option3: "Immutable",
    option4: "Blue/Green",
    ans: 3
  },
  {
    question: "Which ElastiCache strategy involves adding or updating the cache when the database is updated?",
    option1: "Lazy Loading",
    option2: "Cache-Aside",
    option3: "Write Through",
    option4: "Read-Through",
    ans: 3
  },
  {
    question: "In Kinesis, which mode provides 1MB/s in and 2MB/s out per shard?",
    option1: "Provisioned",
    option2: "On-demand",
    option3: "Enhanced",
    option4: "Standard",
    ans: 1
  },
  {
    question: "Which type of scaling is used in ECS Service auto-scaling?",
    option1: "Vertical scaling",
    option2: "Scheduled scaling",
    option3: "Manual scaling",
    option4: "Target tracking",
    ans: 4
  },
  {
    question: "What is the retention period for SQS messages in a standard queue?",
    option1: "1-7 days",
    option2: "4-14 days",
    option3: "7-30 days",
    option4: "14-28 days",
    ans: 2
  },
  {
    question: "What is the maximum number of read replicas you can create for an RDS instance?",
    option1: "5",
    option2: "10",
    option3: "15",
    option4: "20",
    ans: 3
  },
  {
    question: "Which integration type in API Gateway allows for direct interactions between the client and the integrated Lambda function?",
    option1: "AWS",
    option2: "AWS_PROXY",
    option3: "HTTP",
    option4: "HTTP_PROXY",
    ans: 2
  },

  {
    question: "What is the default value for the Cooldown Period in an Auto Scaling Group?",
    option1: "60 seconds",
    option2: "300 seconds",
    option3: "600 seconds",
    option4: "1200 seconds",
    ans: 2
  },
  {
    question: "Which type of SNS can only subscribe to FIFO SQS?",
    option1: "Standard SNS",
    option2: "FIFO SNS",
    option3: "Lambda SNS",
    option4: "Kinesis SNS",
    ans: 2
  },
  {
    question: "What type of integration lets an API method be integrated with the Lambda function invocation action?",
    option1: "AWS",
    option2: "AWS_PROXY",
    option3: "HTTP",
    option4: "HTTP_PROXY",
    ans: 2
  },
  {
    question: "Which deployment option for Elastic Beanstalk involves zero downtime but high cost?",
    option1: "All at once",
    option2: "Rolling",
    option3: "Rolling with additional batches",
    option4: "Immutable",
    ans: 4
  },
  {
    question: "How many read replicas can you have with Amazon Aurora?",
    option1: "5",
    option2: "10",
    option3: "15",
    option4: "20",
    ans: 3
  },
  {
    question: "Which of the following is a feature of Amazon RDS Proxy?",
    option1: "Publicly accessible",
    option2: "Serverless and autoscaling",
    option3: "Supports only MySQL",
    option4: "Increases failover time",
    ans: 2
  },
  {
    question: "What is the retention period range for SQS Standard?",
    option1: "1-7 days",
    option2: "4-14 days",
    option3: "7-30 days",
    option4: "14-28 days",
    ans: 2
  },
  {
    question: "Which type of Kinesis mode supports a maximum of 1MB/sec for data input and 2MB/sec for data output?",
    option1: "Provisioned",
    option2: "On-demand",
    option3: "Enhanced fan-out",
    option4: "Standard",
    ans: 1
  },
  {
    question: "Which deployment option for Elastic Beanstalk involves swapping all new instances when healthy?",
    option1: "All at once",
    option2: "Rolling",
    option3: "Rolling with additional batches",
    option4: "Immutable",
    ans: 4
  },
  {
    question: "What is the buffer interval range for Kinesis Data Firehose?",
    option1: "0-300 seconds",
    option2: "0-600 seconds",
    option3: "0-900 seconds",
    option4: "0-1200 seconds",
    ans: 3
  },

  {
    question: "Which AWS service can be used for real-time data processing?",
    option1: "Amazon Kinesis Data Streams",
    option2: "Amazon S3",
    option3: "Amazon RDS",
    option4: "Amazon SNS",
    ans: 1
  },
  {
    question: "What is the default retention period for Amazon Kinesis Data Streams?",
    option1: "1 day",
    option2: "7 days",
    option3: "30 days",
    option4: "365 days",
    ans: 1
  },
  {
    question: "Which Kinesis mode provides 4 MB/s of data input and output?",
    option1: "Provisioned",
    option2: "On-demand",
    option3: "Enhanced fan-out",
    option4: "Pull data",
    ans: 2
  },
  {
    question: "Which service does Amazon Kinesis Data Firehose NOT support for batch writes?",
    option1: "Amazon S3",
    option2: "Amazon Redshift",
    option3: "Amazon RDS",
    option4: "Amazon OpenSearch",
    ans: 3
  },
  {
    question: "What is the maximum retention period for Amazon Kinesis Data Streams?",
    option1: "30 days",
    option2: "90 days",
    option3: "180 days",
    option4: "365 days",
    ans: 4
  },
  {
    question: "What is the default message visibility timeout in Amazon SQS?",
    option1: "0 seconds",
    option2: "30 seconds",
    option3: "60 seconds",
    option4: "120 seconds",
    ans: 2
  },
  {
    question: "Which type of scaling is NOT supported by AWS Auto Scaling?",
    option1: "Dynamic scaling",
    option2: "Scheduled scaling",
    option3: "Predictive scaling",
    option4: "Manual scaling",
    ans: 4
  },
  {
    question: "Which AWS service is fully managed and serverless for loading data streams into AWS data stores?",
    option1: "Amazon Kinesis Data Streams",
    option2: "Amazon Kinesis Data Firehose",
    option3: "Amazon Redshift",
    option4: "Amazon S3",
    ans: 2
  },
  {
    question: "What is the maximum buffer interval for Amazon Kinesis Data Firehose?",
    option1: "60 seconds",
    option2: "300 seconds",
    option3: "600 seconds",
    option4: "900 seconds",
    ans: 4
  },
  {
    question: "Which AWS service can batch write to S3, Redshift, and OpenSearch?",
    option1: "Amazon Kinesis Data Firehose",
    option2: "Amazon Kinesis Data Streams",
    option3: "Amazon S3",
    option4: "Amazon SNS",
    ans: 1
  },
  {
    question: "What is the minimum buffer size for Amazon Kinesis Data Firehose?",
    option1: "0.5 MB",
    option2: "1 MB",
    option3: "5 MB",
    option4: "10 MB",
    ans: 2
  },
  {
    question: "Which scaling policy adjusts the number of EC2 instances based on target utilization?",
    option1: "Scheduled scaling",
    option2: "Target tracking scaling",
    option3: "Step scaling",
    option4: "Predictive scaling",
    ans: 2
  },
  {
    question: "Which AWS service helps read records from a Kinesis Data Stream?",
    option1: "AWS SDK",
    option2: "AWS CLI",
    option3: "Kinesis Client Library (KCL)",
    option4: "AWS Lambda",
    ans: 3
  },
  {
    question: "Which type of Kinesis Data Stream consumer provides 2 MB/s per consumer per shard?",
    option1: "Standard",
    option2: "Enhanced fan-out",
    option3: "Provisioned",
    option4: "On-demand",
    ans: 2
  },
  {
    question: "Which type of load balancer operates at layer 7 and supports path-based routing?",
    option1: "Classic Load Balancer",
    option2: "Application Load Balancer",
    option3: "Network Load Balancer",
    option4: "Gateway Load Balancer",
    ans: 2
  },
  {
    question: "What is the function of an Internet Gateway in AWS?",
    option1: "Allow instances in a private subnet to access the internet",
    option2: "Connect VPCs to other VPCs",
    option3: "Enable communication between subnets",
    option4: "Provide a static IP address to instances",
    ans: 1
  },
  {
    question: "Which AWS service is used to create a secure, encrypted connection between on-premises data centers and AWS?",
    option1: "AWS Direct Connect",
    option2: "AWS VPN",
    option3: "AWS CloudFormation",
    option4: "AWS Lambda",
    ans: 1
  },
  {
    question: "Which AWS service allows for setting up a managed PostgreSQL or MySQL database?",
    option1: "Amazon RDS",
    option2: "Amazon DynamoDB",
    option3: "Amazon Redshift",
    option4: "Amazon ElastiCache",
    ans: 1
  },
  {
    question: "What is the maximum storage capacity for an Amazon Aurora database?",
    option1: "64 TB",
    option2: "128 TB",
    option3: "256 TB",
    option4: "512 TB",
    ans: 2
  },
  {
    question: "Which feature of Amazon RDS allows for automatic failover between primary and standby databases?",
    option1: "Read Replicas",
    option2: "Multi-AZ Deployment",
    option3: "Snapshots",
    option4: "Enhanced Monitoring",
    ans: 2
  },
  {
    question: "Which Amazon RDS feature improves database availability by reducing failover time?",
    option1: "RDS Proxy",
    option2: "Multi-AZ Deployment",
    option3: "Read Replicas",
    option4: "Automated Backups",
    ans: 1
  },
  {
    question: "Which AWS service can be used to analyze streaming data with SQL?",
    option1: "Amazon Kinesis Data Streams",
    option2: "Amazon Kinesis Data Analytics",
    option3: "Amazon Redshift",
    option4: "Amazon Athena",
    ans: 2
  },
  {
    question: "Which of the following can be used as a source for Amazon Kinesis Data Analytics?",
    option1: "Amazon S3",
    option2: "Amazon DynamoDB",
    option3: "Amazon Kinesis Data Streams",
    option4: "Amazon RDS",
    ans: 3
  },
  {
    question: "What is the purpose of the Server Name Indication (SNI) feature in AWS?",
    option1: "To enable multiple SSL certificates on a single IP",
    option2: "To provide custom domain names for AWS services",
    option3: "To route traffic based on geographic location",
    option4: "To balance load across multiple instances",
    ans: 1
  },
  {
    question: "Which AWS service provides a fully managed document database service?",
    option1: "Amazon RDS",
    option2: "Amazon DynamoDB",
    option3: "Amazon DocumentDB",
    option4: "Amazon Aurora",
    ans: 3
  },
  {
    question: "Which of the following is NOT a valid AWS IAM policy element?",
    option1: "Effect",
    option2: "Action",
    option3: "Resource",
    option4: "State",
    ans: 4
  },
  {
    question: "Which AWS service can be used to host a static website?",
    option1: "Amazon EC2",
    option2: "Amazon S3",
    option3: "Amazon RDS",
    option4: "Amazon Redshift",
    ans: 2
  },
  {
    question: "Which S3 storage class is designed for data that is accessed less frequently but requires rapid access when needed?",
    option1: "S3 Standard",
    option2: "S3 Intelligent-Tiering",
    option3: "S3 Standard-IA",
    option4: "S3 Glacier",
    ans: 3
  },
  {
    question: "What is the maximum size of a single Amazon S3 object?",
    option1: "1 TB",
    option2: "5 TB",
    option3: "10 TB",
    option4: "100 TB",
    ans: 2
  },
  {
    question: "Which of the following AWS services offers automatic scaling and load balancing?",
    option1: "Amazon EC2 Auto Scaling",
    option2: "AWS Lambda",
    option3: "Amazon S3",
    option4: "Amazon RDS",
    ans: 1
  },
  {
    question: "What type of AWS Elastic Load Balancer is best suited for WebSocket traffic?",
    option1: "Classic Load Balancer",
    option2: "Application Load Balancer",
    option3: "Network Load Balancer",
    option4: "Gateway Load Balancer",
    ans: 2
  },
  {
    question: "What is the purpose of AWS CloudFormation?",
    option1: "To automate the deployment of AWS infrastructure",
    option2: "To monitor AWS services",
    option3: "To secure AWS resources",
    option4: "To manage billing and costs",
    ans: 1
  },
  {
    question: "Which of the following services provides a managed message broker service for Apache ActiveMQ?",
    option1: "Amazon SNS",
    option2: "Amazon SQS",
    option3: "Amazon MQ",
    option4: "Amazon Kinesis",
    ans: 3
  },
  {
    question: "Which AWS service helps to monitor and optimize AWS costs?",
    option1: "AWS Config",
    option2: "AWS CloudTrail",
    option3: "AWS Trusted Advisor",
    option4: "AWS Cost Explorer",
    ans: 4
  },
  {
    question: "Which AWS service is used to automate code deployments to EC2 instances?",
    option1: "AWS CodeDeploy",
    option2: "AWS CodeBuild",
    option3: "AWS CodePipeline",
    option4: "AWS CodeCommit",
    ans: 1
  },
  {
    question: "Which of the following AWS services is used for continuous integration and continuous delivery (CI/CD)?",
    option1: "AWS CodePipeline",
    option2: "AWS CodeDeploy",
    option3: "AWS CodeCommit",
    option4: "AWS CodeBuild",
    ans: 1
  },
  {
    question: "Which service provides managed Elasticsearch and OpenSearch services?",
    option1: "Amazon RDS",
    option2: "Amazon ElastiCache",
    option3: "Amazon OpenSearch Service",
    option4: "Amazon Redshift",
    ans: 3
  },
  {
    question: "Which service can be used to automate security assessment of applications deployed on AWS?",
    option1: "AWS Trusted Advisor",
    option2: "AWS Shield",
    option3: "AWS Inspector",
    option4: "AWS Config",
    ans: 3
  },
  {
    question: "Which AWS service helps to identify and protect against DDoS attacks?",
    option1: "AWS Shield",
    option2: "AWS WAF",
    option3: "AWS Firewall Manager",
    option4: "AWS CloudTrail",
    ans: 1
  },
  {
    question: "Which AWS service helps in managing multi-account AWS environments?",
    option1: "AWS Organizations",
    option2: "AWS IAM",
    option3: "AWS CloudFormation",
    option4: "AWS CloudTrail",
    ans: 1
  },
  {
    question: "Which AWS service provides real-time monitoring of AWS resources and applications?",
    option1: "AWS CloudTrail",
    option2: "AWS Config",
    option3: "Amazon CloudWatch",
    option4: "AWS X-Ray",
    ans: 3
  },
  {
    question: "Which feature in Amazon CloudWatch allows you to execute actions when a metric exceeds a threshold?",
    option1: "CloudWatch Logs",
    option2: "CloudWatch Alarms",
    option3: "CloudWatch Events",
    option4: "CloudWatch Dashboards",
    ans: 2
  },
  {
    question: "Which AWS service helps trace user requests through applications built using microservices?",
    option1: "AWS X-Ray",
    option2: "Amazon CloudWatch",
    option3: "AWS Config",
    option4: "AWS CloudTrail",
    ans: 1
  },
  {
    question: "Which AWS service provides a centralized location to discover, access, and share data sets?",
    option1: "AWS Glue",
    option2: "Amazon Athena",
    option3: "AWS Lake Formation",
    option4: "AWS Data Exchange",
    ans: 4
  },
  {
    question: "Which service is used to run containerized applications on a fully managed service?",
    option1: "Amazon EC2",
    option2: "AWS Fargate",
    option3: "Amazon RDS",
    option4: "Amazon S3",
    ans: 2
  },
  {
    question: "Which of the following AWS services provides data warehousing solutions?",
    option1: "Amazon RDS",
    option2: "Amazon DynamoDB",
    option3: "Amazon Redshift",
    option4: "Amazon Aurora",
    ans: 3
  },
  {
    question: "Which AWS service provides a managed platform for deploying machine learning models?",
    option1: "Amazon SageMaker",
    option2: "AWS Lambda",
    option3: "Amazon Rekognition",
    option4: "Amazon Polly",
    ans: 1
  },
  {
    question: "What is the purpose of AWS CloudTrail?",
    option1: "To provide log aggregation",
    option2: "To capture API calls and changes to AWS resources",
    option3: "To monitor the performance of applications",
    option4: "To provide real-time security alerts",
    ans: 2
  },
  {
    question: "Which AWS service can be used to establish a private, low-latency connection between on-premises data centers and AWS?",
    option1: "AWS Direct Connect",
    option2: "AWS VPN",
    option3: "AWS CloudFormation",
    option4: "AWS Lambda",
    ans: 1
  }


];