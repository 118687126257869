import React, { useRef, useState } from "react"
import './Quiz.css'
import { data } from "../../assets/data2";
import errorClickSound from "../../assets/audio/error-audio.mp3";
import yayClickSound from "../../assets/audio/yay-audio.mp3";

const Quiz = ({ onLogout }) => {

    let [index, setIndex] = useState(0);
    let [question, setQuestion] = useState(data[index]);
    let [lock, setLock] = useState(false);
    let [score, setScore] = useState(0);
    let [result, setResult] = useState(false);


    let option1 = useRef(null);
    let option2 = useRef(null);
    let option3 = useRef(null);
    let option4 = useRef(null);

    let option_array = [option1, option2, option3, option4]

    const errorSound = new Audio(errorClickSound);
    const successSound = new Audio(yayClickSound);

    const checkAns = (e, ans) => {
        if (lock === false) {
            if (question.ans === ans) {
                e.target.classList.add("correct");
                playSuccessAudio();
                setLock(true)
                setScore(prev => prev + 1);
            } else {
                e.target.classList.add("wrong");
                playErrorAudio();
                setLock(true)
                option_array[question.ans - 1].current.classList.add("correct");
            }
        }
    }


    const playErrorAudio = () => {
        errorSound.play();
    }

    const playSuccessAudio = () => {
        successSound.play();
    }

    const next = () => {
        if (lock === true) {
            if (index === data.length - 1) {
                setResult(true);
                return 0;
            }
            setIndex(++index);
            setQuestion(data[index]);
            setLock(false);
            option_array.map((option) => {
                option.current.classList.remove("wrong");
                option.current.classList.remove("correct");
                return null;
            })
        }
    }


    const reset = () => {
        setIndex(0);
        setQuestion(data[0]);
        setScore(0);
        setLock(false);
        setResult(false);
    }

    const handleLogout = () => {
        onLogout(); // Call onLogout function passed from App.js
    };


    return (
        <div className="container mt-3 mb-3 bg-primary">
            <div className="row justify-content-center">
                <div className="col-lg-8 questions-box">
                    <h1 className="text-primary">AWS DVA-C02 Prep.</h1>
                    <hr />
                    {result ? <></> : <>
                        <h4><span className="text-danger">{index + 1}.</span> {question.question}</h4>
                        <ul className="mt-3">
                            <li className="mt-3" ref={option1} onClick={(e) => { checkAns(e, 1) }}>{question.option1}</li>
                            <li className="mt-3" ref={option2} onClick={(e) => { checkAns(e, 2) }}>{question.option2}</li>
                            <li className="mt-3" ref={option3} onClick={(e) => { checkAns(e, 3) }}>{question.option3}</li>
                            <li className="mt-3" ref={option4} onClick={(e) => { checkAns(e, 4) }}>{question.option4}</li>
                        </ul>

                        <div className="d-grid gap-2">
                            <button onClick={next} className={"btn pd-2" + (lock ? ' btn-primary' : ' btn-secondary')}>Next</button>
                        </div>

                        <div className="index text-center pt-3">{index + 1} of {data.length} questions</div>
                    </>}

                    {result ? <>
                        <h3>You Scored {score} out of {data.length} questions</h3>
                        <button onClick={reset} className="btn btn-primary btn-lg mt-3">Reset</button>
                    </> : <></>}
                    <hr></hr>

                    <button onClick={handleLogout} className="btn btn-danger btn-sm mt-3">Logout</button>
                </div>
            </div>
        </div>
    )
}

export default Quiz