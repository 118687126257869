/* find the duplicates in the following questions */
export const data = [
    {
        question: "Your application requires low latency and high performance for read-heavy workloads. Which AWS service should you use to offload the database for read operations?",
        option1: "Amazon RDS with Multi-AZ",
        option2: "Amazon ElastiCache",
        option3: "Amazon S3",
        option4: "AWS Lambda",
        ans: 2
    },
    {
        question: "You are managing an application that requires a database to automatically scale with unpredictable traffic. Which fully managed, serverless, and highly available service would you choose?",
        option1: "Amazon DynamoDB",
        option2: "Amazon RDS Proxy",
        option3: "Amazon Aurora",
        option4: "Amazon S3",
        ans: 1
    },
    {
        question: "Your web application needs to handle rapid failover to a standby database in a different AWS region in case of a regional disruption. What setup would you use?",
        option1: "Multi-AZ RDS deployment",
        option2: "RDS read replicas across regions",
        option3: "RDS Proxy",
        option4: "Amazon ElastiCache",
        ans: 2
    },
    {
        question: "You need to store and retrieve objects that are infrequently accessed but require rapid access when needed. Which S3 storage class is most appropriate?",
        option1: "S3 Standard",
        option2: "S3 Standard-IA",
        option3: "S3 Glacier Instant Retrieval",
        option4: "S3 One Zone-IA",
        ans: 2
    },
    {
        question: "An application deployed in an AWS environment needs to ensure that API requests do not exceed a set limit to prevent throttling. Which method should be implemented?",
        option1: "Use exponential backoff in the client application",
        option2: "Increase the number of instances handling the requests",
        option3: "Use AWS Shield to protect the API",
        option4: "Deploy the application in multiple regions",
        ans: 1
    },
    {
        question: "You need to enforce SSL/TLS encryption for an S3 bucket and ensure that all objects are encrypted by default. Which method should you use?",
        option1: "Use AWS Config to enforce encryption",
        option2: "Set a bucket policy requiring encryption",
        option3: "Enable MFA Delete on the bucket",
        option4: "Use Amazon Macie for monitoring",
        ans: 2
    },
    {
        question: "To maintain stateful sessions in a scalable web application deployed on multiple EC2 instances, which service should be used to store session data?",
        option1: "Amazon RDS",
        option2: "Amazon S3",
        option3: "Amazon ElastiCache",
        option4: "AWS Lambda",
        ans: 3
    },
    {
        question: "Your application running on ECS needs to manage sensitive configuration data such as database passwords. Which AWS service would you use?",
        option1: "Amazon RDS",
        option2: "AWS Secrets Manager",
        option3: "Amazon S3",
        option4: "AWS CloudFormation",
        ans: 2
    },
    {
        question: "You are designing a high-availability application that requires automatic failover and minimal recovery time. Which RDS feature should you use?",
        option1: "RDS Read Replicas",
        option2: "RDS Multi-AZ",
        option3: "RDS Proxy",
        option4: "RDS Performance Insights",
        ans: 2
    },
    {
        question: "Your application needs to distribute incoming web traffic across multiple EC2 instances. Which AWS service would you use?",
        option1: "Amazon Route 53",
        option2: "AWS CloudFront",
        option3: "Elastic Load Balancing",
        option4: "Amazon CloudWatch",
        ans: 3
    },
    {
        question: "You have a batch processing job that runs daily and needs temporary storage for intermediate data. Which storage option is cost-effective and suitable?",
        option1: "Amazon S3",
        option2: "Amazon EFS",
        option3: "Amazon EBS",
        option4: "AWS Snowball",
        ans: 1
    },
    {
        question: "Your company needs to migrate an on-premises database to AWS with minimal downtime. Which AWS service can help with this migration?",
        option1: "AWS DMS (Database Migration Service)",
        option2: "Amazon RDS",
        option3: "AWS Snowball",
        option4: "AWS Backup",
        ans: 1
    },
    {
        question: "You need to monitor the CPU usage of your EC2 instances and set an alarm when it exceeds a threshold. Which AWS service should you use?",
        option1: "AWS CloudTrail",
        option2: "Amazon CloudWatch",
        option3: "AWS Config",
        option4: "AWS Trusted Advisor",
        ans: 2
    },
    {
        question: "Your application requires a managed Hadoop framework to process large data sets. Which AWS service should you choose?",
        option1: "Amazon EMR",
        option2: "Amazon Redshift",
        option3: "AWS Glue",
        option4: "Amazon Athena",
        ans: 1
    },
    {
        question: "You need to ensure that your AWS resources are provisioned in a secure and compliant manner. Which AWS service helps automate compliance checks?",
        option1: "AWS Config",
        option2: "AWS CloudFormation",
        option3: "AWS CloudTrail",
        option4: "AWS CodeDeploy",
        ans: 1
    },
    {
        question: "Your web application must securely store user-uploaded files. Which AWS service should you use?",
        option1: "Amazon S3",
        option2: "Amazon RDS",
        option3: "Amazon EBS",
        option4: "Amazon DynamoDB",
        ans: 1
    },
    {
        question: "You need to analyze and visualize real-time data streams from IoT devices. Which AWS service should you use?",
        option1: "Amazon Kinesis",
        option2: "Amazon Redshift",
        option3: "AWS Glue",
        option4: "Amazon RDS",
        ans: 1
    },
    {
        question: "Your application requires low-latency, in-memory data storage and caching. Which AWS service should you use?",
        option1: "Amazon DynamoDB",
        option2: "Amazon ElastiCache",
        option3: "Amazon RDS",
        option4: "Amazon S3",
        ans: 2
    },
    {
        question: "You need to host a static website with global distribution and high availability. Which AWS service combination should you use?",
        option1: "Amazon EC2 and Amazon RDS",
        option2: "Amazon S3 and Amazon CloudFront",
        option3: "Amazon EBS and Amazon Route 53",
        option4: "Amazon ECS and Amazon ELB",
        ans: 2
    },
    {
        question: "Your company needs to track changes to AWS resources for security and auditing purposes. Which AWS service provides this capability?",
        option1: "AWS Config",
        option2: "AWS CloudTrail",
        option3: "Amazon CloudWatch",
        option4: "AWS Trusted Advisor",
        ans: 2
    },
    {
        question: "You need to ensure that a web application deployed in an Auto Scaling group scales based on CPU utilization. Which service helps you achieve this?",
        option1: "Amazon CloudWatch",
        option2: "AWS CloudTrail",
        option3: "AWS Lambda",
        option4: "AWS Elastic Beanstalk",
        ans: 1
    },
    {
        question: "Your application requires database read replicas for improved read performance. Which AWS service should you use?",
        option1: "Amazon RDS",
        option2: "Amazon DynamoDB",
        option3: "Amazon S3",
        option4: "Amazon ElastiCache",
        ans: 1
    },
    {
        question: "You need to orchestrate multiple Docker container applications. Which AWS service should you use?",
        option1: "Amazon ECS",
        option2: "AWS Lambda",
        option3: "Amazon S3",
        option4: "Amazon RDS",
        ans: 1
    },
    {
        question: "You are deploying a Node.js application and want to use a managed service to handle the underlying infrastructure. Which AWS service should you choose?",
        option1: "AWS Lambda",
        option2: "Amazon EC2",
        option3: "AWS Elastic Beanstalk",
        option4: "Amazon ECS",
        ans: 3
    },
    {
        question: "Your team needs a collaborative environment for code development with support for continuous integration and delivery. Which AWS service provides this functionality?",
        option1: "AWS CodeBuild",
        option2: "AWS CodeDeploy",
        option3: "AWS CodePipeline",
        option4: "AWS CodeStar",
        ans: 4
    },
    {
        question: "Your organization needs to implement a data warehouse solution that can handle petabytes of data and perform complex queries efficiently. Which AWS service should you use?",
        option1: "Amazon RDS",
        option2: "Amazon DynamoDB",
        option3: "Amazon Redshift",
        option4: "Amazon S3",
        ans: 3
    },
    {
        question: "You need to perform extract, transform, and load (ETL) operations on large datasets stored in S3. Which AWS service should you use?",
        option1: "Amazon Athena",
        option2: "AWS Glue",
        option3: "Amazon EMR",
        option4: "Amazon Redshift",
        ans: 2
    },
    {
        question: "Your application needs to subscribe to notifications from other applications. Which AWS service should you use to set up a pub/sub messaging model?",
        option1: "Amazon SQS",
        option2: "Amazon SNS",
        option3: "AWS Step Functions",
        option4: "AWS Lambda",
        ans: 2
    },
    {
        question: "You need to manage a set of identical worker nodes that should automatically replace any failed instances. Which AWS feature should you use?",
        option1: "Amazon EC2 Auto Scaling",
        option2: "AWS Lambda",
        option3: "Amazon RDS",
        option4: "AWS CloudFormation",
        ans: 1
    },
    {
        question: "You want to optimize your AWS cost by stopping underutilized instances automatically. Which service should you use to automate this?",
        option1: "AWS Config",
        option2: "AWS CloudFormation",
        option3: "AWS CloudTrail",
        option4: "AWS Lambda with CloudWatch",
        ans: 4
    },
    {
        question: "Your application requires event-driven serverless computing to run code in response to triggers such as S3 events or DynamoDB updates. Which AWS service should you use?",
        option1: "Amazon EC2",
        option2: "AWS Lambda",
        option3: "Amazon S3",
        option4: "Amazon RDS",
        ans: 2
    },
    {
        question: "You need to set up a VPN connection between your on-premises network and your VPC. Which AWS service should you use?",
        option1: "AWS Direct Connect",
        option2: "AWS VPN",
        option3: "Amazon VPC Peering",
        option4: "Amazon Route 53",
        ans: 2
    },
    {
        question: "Your application requires the ability to retrieve data from multiple S3 buckets in different regions using SQL queries. Which service should you use?",
        option1: "Amazon Athena",
        option2: "Amazon Redshift Spectrum",
        option3: "Amazon RDS",
        option4: "AWS Glue",
        ans: 1
    },
    {
        question: "Your team needs to perform real-time stream processing of data from multiple sources. Which AWS service should you use?",
        option1: "Amazon S3",
        option2: "Amazon Kinesis",
        option3: "Amazon RDS",
        option4: "AWS Glue",
        ans: 2
    },
    {
        question: "You need to distribute traffic to multiple EC2 instances across different availability zones. Which AWS service should you use?",
        option1: "Amazon Route 53",
        option2: "AWS CloudFront",
        option3: "Elastic Load Balancing",
        option4: "Amazon CloudWatch",
        ans: 3
    },
    {
        question: "Your application needs a highly available and managed MySQL database with automatic backups. Which AWS service should you use?",
        option1: "Amazon Aurora",
        option2: "Amazon DynamoDB",
        option3: "Amazon S3",
        option4: "AWS Lambda",
        ans: 1
    },
    {
        question: "You need to host a static website with content that can be cached at edge locations worldwide. Which AWS services should you use?",
        option1: "Amazon EC2 and Amazon RDS",
        option2: "Amazon S3 and Amazon CloudFront",
        option3: "Amazon EBS and Amazon Route 53",
        option4: "Amazon ECS and Amazon ELB",
        ans: 2
    },
    {
        question: "Your application must store data in a NoSQL database that scales automatically with no downtime. Which AWS service should you use?",
        option1: "Amazon RDS",
        option2: "Amazon DynamoDB",
        option3: "Amazon S3",
        option4: "Amazon Redshift",
        ans: 2
    },
    {
        question: "You need to encrypt your data at rest within an RDS database. Which feature should you enable?",
        option1: "Multi-AZ",
        option2: "Read Replicas",
        option3: "RDS Encryption",
        option4: "RDS Proxy",
        ans: 3
    },
    {
        question: "Your application must respond to HTTP requests and dynamically serve content based on the request path. Which service should you use?",
        option1: "Amazon Route 53",
        option2: "AWS CloudFront",
        option3: "Application Load Balancer",
        option4: "Amazon RDS",
        ans: 3
    },
    {
        question: "You need to set up an S3 bucket to host a static website. What configuration must be enabled on the S3 bucket?",
        option1: "Enable versioning",
        option2: "Enable static website hosting",
        option3: "Enable server-side encryption",
        option4: "Enable logging",
        ans: 2
    },
    {
        question: "Your application requires a serverless compute service to run code without provisioning or managing servers. Which AWS service should you choose?",
        option1: "Amazon EC2",
        option2: "AWS Lambda",
        option3: "Amazon RDS",
        option4: "Amazon ECS",
        ans: 2
    },
    {
        question: "You need a managed service to detect and protect against DDoS attacks. Which AWS service provides this capability?",
        option1: "AWS Shield",
        option2: "AWS WAF",
        option3: "AWS IAM",
        option4: "Amazon GuardDuty",
        ans: 1
    },
    {
        question: "Your team needs a centralized repository for managing and versioning your infrastructure as code. Which AWS service should you use?",
        option1: "AWS CodeBuild",
        option2: "AWS CodePipeline",
        option3: "AWS CodeCommit",
        option4: "AWS CloudFormation",
        ans: 3
    },
    {
        question: "Your company requires a scalable, managed file system that can be mounted on multiple EC2 instances. Which AWS service should you use?",
        option1: "Amazon S3",
        option2: "Amazon EFS",
        option3: "Amazon EBS",
        option4: "AWS Snowball",
        ans: 2
    },
    {
        question: "You need to analyze log data stored in S3 using standard SQL. Which AWS service should you use?",
        option1: "Amazon Athena",
        option2: "AWS Glue",
        option3: "Amazon Redshift",
        option4: "Amazon RDS",
        ans: 1
    },
    {
        question: "Your application must process and analyze large datasets using Apache Hadoop. Which AWS service should you use?",
        option1: "Amazon Redshift",
        option2: "AWS Glue",
        option3: "Amazon EMR",
        option4: "Amazon RDS",
        ans: 3
    },
    {
        question: "You need to automate the deployment of your applications across multiple environments. Which AWS service should you use?",
        option1: "AWS CodeBuild",
        option2: "AWS CodeDeploy",
        option3: "AWS CodePipeline",
        option4: "AWS CloudFormation",
        ans: 2
    },
    {
        question: "Your application requires real-time data processing and transformation from multiple streams. Which AWS service should you use?",
        option1: "Amazon Kinesis Data Streams",
        option2: "Amazon S3",
        option3: "AWS Glue",
        option4: "Amazon RDS",
        ans: 1
    },
    {
        question: "You need to create a VPC with public and private subnets. Which AWS service provides this functionality?",
        option1: "Amazon Route 53",
        option2: "AWS CloudFormation",
        option3: "Amazon VPC",
        option4: "AWS Direct Connect",
        ans: 3
    },
    {
        question: "Your team needs to develop, build, and deploy a serverless application. Which AWS service provides a unified interface for this workflow?",
        option1: "AWS CodePipeline",
        option2: "AWS CodeBuild",
        option3: "AWS CodeStar",
        option4: "AWS CodeDeploy",
        ans: 3
    },
    {
        question: "You need to manage identities and permissions for users accessing AWS resources. Which AWS service should you use?",
        option1: "AWS IAM",
        option2: "Amazon Cognito",
        option3: "AWS Secrets Manager",
        option4: "Amazon GuardDuty",
        ans: 1
    },
    {
        question: "Your application requires data warehousing for large datasets and complex queries. Which AWS service should you use?",
        option1: "Amazon RDS",
        option2: "Amazon DynamoDB",
        option3: "Amazon Redshift",
        option4: "Amazon S3",
        ans: 3
    },
    {
        question: "You need to monitor your AWS resources and applications in real-time. Which AWS service should you use?",
        option1: "AWS CloudTrail",
        option2: "Amazon CloudWatch",
        option3: "AWS Config",
        option4: "AWS Trusted Advisor",
        ans: 2
    },
    {
        question: "Your organization needs a managed service to run and scale Docker containers. Which AWS service should you use?",
        option1: "Amazon EC2",
        option2: "Amazon ECS",
        option3: "AWS Lambda",
        option4: "Amazon S3",
        ans: 2
    },
    {
        question: "Your application requires secure storage for encryption keys with audit logging. Which AWS service should you use?",
        option1: "AWS KMS",
        option2: "AWS Secrets Manager",
        option3: "Amazon GuardDuty",
        option4: "AWS CloudHSM",
        ans: 1
    },
    {
        question: "You need to deploy and manage a set of identical, self-healing instances across multiple AZs. Which AWS service should you use?",
        option1: "AWS Elastic Beanstalk",
        option2: "AWS Auto Scaling",
        option3: "AWS OpsWorks",
        option4: "AWS CloudFormation",
        ans: 2
    },
    {
        question: "Your team needs to implement infrastructure as code to automate resource provisioning. Which AWS service should you use?",
        option1: "AWS CloudFormation",
        option2: "AWS CodeDeploy",
        option3: "AWS CodeBuild",
        option4: "AWS CloudTrail",
        ans: 1
    },
    {
        question: "Your application needs to ingest and store large volumes of streaming data in real-time. Which AWS service should you use?",
        option1: "Amazon Kinesis Data Firehose",
        option2: "Amazon S3",
        option3: "Amazon RDS",
        option4: "AWS Glue",
        ans: 1
    },
    {
        question: "You need to create a managed database with automated backups, patching, and scaling. Which AWS service should you use?",
        option1: "Amazon EC2",
        option2: "Amazon RDS",
        option3: "AWS Lambda",
        option4: "Amazon S3",
        ans: 2
    },
    {
        question: "Your team needs to analyze structured data using SQL queries in a data warehouse. Which AWS service should you use?",
        option1: "Amazon RDS",
        option2: "Amazon DynamoDB",
        option3: "Amazon Redshift",
        option4: "AWS Glue",
        ans: 3
    },
    {
        question: "Your application requires real-time anomaly detection in your data. Which AWS service should you use?",
        option1: "Amazon CloudWatch",
        option2: "Amazon GuardDuty",
        option3: "Amazon Kinesis",
        option4: "AWS Lambda",
        ans: 3
    },
    {
        question: "Your application needs a highly available managed database with automatic multi-AZ replication. Which AWS service should you use?",
        option1: "Amazon RDS",
        option2: "Amazon DynamoDB",
        option3: "Amazon S3",
        option4: "AWS Lambda",
        ans: 1
    },
    {
        question: "Your team needs to build a real-time recommendation system. Which AWS service should you use?",
        option1: "Amazon S3",
        option2: "Amazon DynamoDB",
        option3: "Amazon Personalize",
        option4: "AWS Glue",
        ans: 3
    },
    {
        question: "Your application must log API calls for auditing and compliance. Which AWS service should you use?",
        option1: "AWS CloudTrail",
        option2: "Amazon CloudWatch",
        option3: "AWS Config",
        option4: "AWS Trusted Advisor",
        ans: 1
    },
    {
        question: "Your application requires long-term archival storage with retrieval times in minutes to hours. Which AWS service should you use?",
        option1: "Amazon S3 Standard",
        option2: "Amazon S3 Glacier",
        option3: "Amazon S3 Intelligent-Tiering",
        option4: "Amazon S3 One Zone-IA",
        ans: 2
    },
    {
        question: "Your organization needs a serverless data warehouse to analyze data across your data lake. Which AWS service should you use?",
        option1: "Amazon Redshift",
        option2: "AWS Glue",
        option3: "Amazon Athena",
        option4: "Amazon RDS",
        ans: 3
    },
    {
        question: "You need to run a fully managed relational database that is compatible with MySQL and PostgreSQL. Which AWS service should you use?",
        option1: "Amazon Aurora",
        option2: "Amazon RDS",
        option3: "Amazon DynamoDB",
        option4: "Amazon Redshift",
        ans: 1
    },
    {
        question: "Your team needs to set up an event-driven workflow for processing data in multiple stages. Which AWS service should you use?",
        option1: "Amazon S3",
        option2: "AWS Step Functions",
        option3: "AWS Lambda",
        option4: "Amazon RDS",
        ans: 2
    },
    {
        question: "Your application needs to cache frequently accessed data to improve performance. Which AWS service should you use?",
        option1: "Amazon S3",
        option2: "AWS Lambda",
        option3: "Amazon ElastiCache",
        option4: "Amazon RDS",
        ans: 3
    },
    {
        question: "Your application must analyze logs for security threats. Which AWS service should you use?",
        option1: "AWS CloudTrail",
        option2: "Amazon GuardDuty",
        option3: "Amazon CloudWatch",
        option4: "AWS Config",
        ans: 2
    },
    {
        question: "Your organization needs to establish a private, dedicated connection between its data center and AWS. Which service should you use?",
        option1: "AWS Direct Connect",
        option2: "AWS VPN",
        option3: "Amazon VPC Peering",
        option4: "AWS Transit Gateway",
        ans: 1
    },
    {
        question: "You need to host a static website and serve it with low latency globally. Which combination of services should you use?",
        option1: "Amazon S3 and Amazon CloudFront",
        option2: "Amazon EC2 and Amazon RDS",
        option3: "Amazon S3 and AWS WAF",
        option4: "Amazon EFS and Amazon CloudFront",
        ans: 1
    },
    {
        question: "Your application needs to store and retrieve JSON documents with high availability and low latency. Which AWS service should you use?",
        option1: "Amazon RDS",
        option2: "Amazon DynamoDB",
        option3: "Amazon S3",
        option4: "Amazon ElastiCache",
        ans: 2
    },
    {
        question: "You need to migrate a large amount of data from an on-premises data center to AWS. Which service should you use?",
        option1: "AWS Snowball",
        option2: "AWS Direct Connect",
        option3: "AWS Storage Gateway",
        option4: "AWS DMS",
        ans: 1
    },
    {
        question: "Your organization needs to track configuration changes to AWS resources. Which AWS service should you use?",
        option1: "AWS CloudTrail",
        option2: "AWS Config",
        option3: "Amazon CloudWatch",
        option4: "AWS Trusted Advisor",
        ans: 2
    },
    {
        question: "Your application needs to securely store and retrieve credentials for third-party services. Which AWS service should you use?",
        option1: "AWS KMS",
        option2: "AWS Secrets Manager",
        option3: "AWS IAM",
        option4: "AWS CloudHSM",
        ans: 2
    },
    {
        question: "Your team needs to automate the deployment of applications using code stored in Git repositories. Which AWS service should you use?",
        option1: "AWS CodeCommit",
        option2: "AWS CodeBuild",
        option3: "AWS CodeDeploy",
        option4: "AWS CodePipeline",
        ans: 4
    },
    {
        question: "You need to perform batch processing on a large dataset stored in S3. Which AWS service should you use?",
        option1: "AWS Batch",
        option2: "AWS Lambda",
        option3: "Amazon RDS",
        option4: "Amazon S3",
        ans: 1
    },
    {
        question: "Your application requires automatic scaling based on demand. Which AWS service should you use?",
        option1: "AWS Auto Scaling",
        option2: "Amazon RDS",
        option3: "AWS CloudFormation",
        option4: "AWS CloudTrail",
        ans: 1
    },
    {
        question: "You need to run SQL queries against data stored in S3 without moving the data to a database. Which AWS service should you use?",
        option1: "Amazon Athena",
        option2: "Amazon Redshift",
        option3: "Amazon RDS",
        option4: "AWS Glue",
        ans: 1
    },
    {
        question: "Your team needs to implement a notification system for changes to S3 objects. Which AWS service should you use?",
        option1: "Amazon SNS",
        option2: "Amazon SQS",
        option3: "AWS Lambda",
        option4: "AWS CloudFormation",
        ans: 1
    },
    {
        question: "Your organization requires a secure way to manage Docker container secrets. Which AWS service should you use?",
        option1: "AWS Secrets Manager",
        option2: "AWS KMS",
        option3: "AWS IAM",
        option4: "AWS CloudHSM",
        ans: 1
    },
    {
        question: "Your application must process large-scale data analytics and machine learning tasks. Which AWS service should you use?",
        option1: "Amazon EMR",
        option2: "AWS Glue",
        option3: "Amazon Athena",
        option4: "Amazon RDS",
        ans: 1
    },
    {
        question: "You need to create a managed Apache Kafka service for real-time data streaming. Which AWS service should you use?",
        option1: "Amazon Kinesis",
        option2: "Amazon MSK",
        option3: "Amazon SNS",
        option4: "Amazon SQS",
        ans: 2
    },
    {
        question: "Your organization needs to analyze large datasets with complex queries and high performance. Which AWS service should you use?",
        option1: "Amazon RDS",
        option2: "Amazon Redshift",
        option3: "Amazon DynamoDB",
        option4: "AWS Glue",
        ans: 2
    },
    {
        question: "Your team needs to implement CI/CD for applications stored in a Git repository. Which AWS service should you use?",
        option1: "AWS CodeCommit",
        option2: "AWS CodePipeline",
        option3: "AWS CodeDeploy",
        option4: "AWS CodeBuild",
        ans: 2
    },
    {
        question: "You need to store and manage Docker container images. Which AWS service should you use?",
        option1: "Amazon ECS",
        option2: "AWS CodeCommit",
        option3: "Amazon ECR",
        option4: "AWS Lambda",
        ans: 3
    },
    {
        question: "Your organization needs to automate the deployment of machine learning models. Which AWS service should you use?",
        option1: "Amazon SageMaker",
        option2: "AWS Lambda",
        option3: "Amazon EMR",
        option4: "Amazon Athena",
        ans: 1
    },
    {
        question: "Your application requires managed message queuing to decouple microservices. Which AWS service should you use?",
        option1: "Amazon SQS",
        option2: "Amazon SNS",
        option3: "Amazon Kinesis",
        option4: "AWS Lambda",
        ans: 1
    },
    {
        question: "Your team needs to analyze real-time data streams. Which AWS service should you use?",
        option1: "Amazon Kinesis Data Streams",
        option2: "Amazon S3",
        option3: "AWS Glue",
        option4: "Amazon RDS",
        ans: 1
    },
    {
        question: "Your organization needs to establish a dedicated network connection between your data center and AWS. Which AWS service should you use?",
        option1: "AWS Direct Connect",
        option2: "AWS VPN",
        option3: "Amazon VPC Peering",
        option4: "AWS Transit Gateway",
        ans: 1
    },
    {
        question: "You need to orchestrate serverless workflows with complex logic. Which AWS service should you use?",
        option1: "Amazon S3",
        option2: "AWS Step Functions",
        option3: "AWS Lambda",
        option4: "Amazon RDS",
        ans: 2
    },
    {
        question: "Your application needs to cache frequently accessed data to improve performance. Which AWS service should you use?",
        option1: "Amazon S3",
        option2: "AWS Lambda",
        option3: "Amazon ElastiCache",
        option4: "Amazon RDS",
        ans: 3
    },
    {
        question: "Your organization needs to track user activity and API usage across your AWS infrastructure. Which AWS service should you use?",
        option1: "AWS CloudTrail",
        option2: "Amazon CloudWatch",
        option3: "AWS Config",
        option4: "AWS Trusted Advisor",
        ans: 1
    },
    {
        question: "Your application requires real-time stream processing and analytics. Which AWS service should you use?",
        option1: "Amazon Kinesis",
        option2: "AWS Lambda",
        option3: "Amazon Athena",
        option4: "Amazon RDS",
        ans: 1
    },
    {
        question: "Your team needs to automate the deployment of infrastructure based on code. Which AWS service should you use?",
        option1: "AWS CloudFormation",
        option2: "AWS CodeDeploy",
        option3: "AWS CodeBuild",
        option4: "AWS CloudTrail",
        ans: 1
    },
    {
        question: "Your application requires a highly available managed database with automatic backups and scaling. Which AWS service should you use?",
        option1: "Amazon EC2",
        option2: "Amazon RDS",
        option3: "AWS Lambda",
        option4: "Amazon S3",
        ans: 2
    },
    {
        question: "Your organization needs to perform data warehousing and complex queries on large datasets. Which AWS service should you use?",
        option1: "Amazon RDS",
        option2: "Amazon DynamoDB",
        option3: "Amazon Redshift",
        option4: "Amazon S3",
        ans: 3
    },
    {
        question: "Your team needs to monitor the health and performance of your AWS resources. Which AWS service should you use?",
        option1: "AWS CloudTrail",
        option2: "Amazon CloudWatch",
        option3: "AWS Config",
        option4: "AWS Trusted Advisor",
        ans: 2
    },
    {
        question: "Your application requires a highly available managed database with automatic multi-AZ replication. Which AWS service should you use?",
        option1: "Amazon RDS",
        option2: "Amazon DynamoDB",
        option3: "Amazon S3",
        option4: "AWS Lambda",
        ans: 1
    },
    {
        question: "Your team needs to build a real-time recommendation system. Which AWS service should you use?",
        option1: "Amazon S3",
        option2: "Amazon DynamoDB",
        option3: "Amazon Personalize",
        option4: "AWS Glue",
        ans: 3
    },
    {
        question: "Your application must log API calls for auditing and compliance. Which AWS service should you use?",
        option1: "AWS CloudTrail",
        option2: "Amazon CloudWatch",
        option3: "AWS Config",
        option4: "AWS Trusted Advisor",
        ans: 1
    },
    {
        question: "Your organization needs to encrypt data at rest and in transit. Which AWS service provides key management and encryption?",
        option1: "AWS KMS",
        option2: "AWS Secrets Manager",
        option3: "AWS IAM",
        option4: "AWS CloudHSM",
        ans: 1
    }
]
